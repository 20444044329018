import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import HeadingCard from 'components/Card/HeadingCard';
import React from 'react';
import { LocationResponseType } from 'types/api/temple';
import TempleLocationMap from '../TempleLocationMap';
import { useStyles } from './style'

interface TempleLocationCardProps {
    location: LocationResponseType
    apiKey: string
}

const TempleLocationCard: React.FC<TempleLocationCardProps> = ({ location, apiKey }) => {
    const classes = useStyles()
    return (
        <HeadingCard title="Location" cardContent={false}>
            <CardMedia>
                <Box className={classes.wrapper}>
                    <TempleLocationMap location={location} apiKey={apiKey} showSearchBox={false} fixed={true} draggable={false} />
                </Box>
            </CardMedia>
        </HeadingCard>
    );
};

export default TempleLocationCard;