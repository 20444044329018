import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import { unwrapResult } from '@reduxjs/toolkit'
import FormikTextField from 'components/Fields/FormikTextField'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { apiStatus } from 'shared/constants/AppEnum'
import { useAppDispatch } from 'store/hooks'
import { templeUpdateThunk } from 'store/reducers/temple/templeSlice'
import { ITempleResponse } from 'types/api/temple'
import { IApiState } from 'store/types'
import { extractError, isFieldError } from 'utils/api'
import * as yup from 'yup'
import { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel } from '@material-ui/core'
import _ from 'lodash';
import Settings from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from 'containers/UserInvitation/styles'



const validateRefNoTpl = yup.string()
    .matches(
        /^(?:[a-zA-Z_/-]*|\[year\]|\[month\]|\[finyearstart\]|\[finyearend\]|\[no\])*$/,
        "Invalid format. Allowed: a-z, A-Z, _, and placeholders [year], [month], [finyearstart], [finyearend], [no]."
    )
    .test(
        "no-placeholder-required",
        "[no] is required in the template.",
        (value) => (value !== undefined && value.includes("[no]"))
    )
    .test(
        "year-requires-month",
        "[month] is required when [year] is provided.",
        (value) => !(value && value.includes("[year]") && !value.includes("[month]"))
    )
    .test(
        "finyearstart-requires-finyearend",
        "[finyearend] is required when [finyearstart] is provided.",
        (value) => !(value && value.includes("[finyearstart]") && !value.includes("[finyearend]"))
    )
    .test(
        "finyearend-requires-finyearstart",
        "[finyearstart] is required when [finyearend] is provided.",
        (value) => !(value && value.includes("[finyearend]") && !value.includes("[finyearstart]"))
    )
    .test(
        "unique-placeholders",
        "Placeholders can only appear once in the template.",
        (value) => {
            const placeholders = ["year", "month", "finyearstart", "finyearend", "no"];
            return placeholders.every(
                (ph) => (value?.match(new RegExp(`\\[${ph}\\]`, "g")) || []).length <= 1
            );
        }
    );


interface TempleFormProps {
    temple?: ITempleResponse
    showMap?: boolean
    api: IApiState
    buttonLabelId?: string
    successCb?: () => void
}

type RefResetFormType = {
    resetRefOnYearChange: boolean | '';
    refNoPadding: string;
    orderRefNoTpl: string;
    offeringReceiptRefNoTpl: string;
    donationReceiptRefNoTpl: string;
    voucherRefNoTpl: string;
    invoiceRefNoTpl: string;
};

export type RefResetFormApiType = Omit<RefResetFormType, 'refNoPadding'> & {
    id: string | number,
    refNoPadding: number;
}



const formtoAPi = (values: RefResetFormType, initialValues: RefResetFormType): Partial<RefResetFormApiType> => {
    const changedFields = _.pickBy(values, (value, key) => !_.isEqual(value, initialValues[key as keyof RefResetFormType]));
    return {
        ...changedFields,
        refNoPadding: Number(changedFields.refNoPadding ?? values.refNoPadding), // Ensure refNoPadding is a number
    };
};


const getInitialValues = (temple?: ITempleResponse): RefResetFormType => {
    return {
        resetRefOnYearChange: temple?.resetRefOnYearChange ?? '',
        refNoPadding: temple?.refNoPadding?.toString() ?? '',
        orderRefNoTpl: temple?.orderRefNoTpl ?? '',
        offeringReceiptRefNoTpl: temple?.offeringReceiptRefNoTpl ?? '',
        donationReceiptRefNoTpl: temple?.donationReceiptRefNoTpl ?? '',
        voucherRefNoTpl: temple?.voucherRefNoTpl ?? '',
        invoiceRefNoTpl: temple?.invoiceRefNoTpl ?? '',
    }
}



const ReferenceResetForm: React.FC<TempleFormProps> =
    ({ temple, api, showMap = false, buttonLabelId = 'common.save', successCb }) => {
        const { messages } = useIntl()
        const initialValues = useMemo(() => getInitialValues(temple), [temple])
        const dispatch = useAppDispatch()
        const [expanded, setExpanded] = useState(false)
        const { enqueueSnackbar } = useSnackbar()

        const handleAccordion = () => {
            setExpanded(!expanded)
        }

        const validationSchema = yup.object({
            resetRefOnYearChange: yup.boolean(),
            refNoPadding: yup
                .number()
                .typeError("Padding must be a number.")
                .min(0, "Padding must be at least 0.")
                .max(20, "Padding cannot exceed 20."),
            orderRefNoTpl: validateRefNoTpl,
            offeringReceiptRefNoTpl: validateRefNoTpl,
            donationReceiptRefNoTpl: validateRefNoTpl,
            voucherRefNoTpl: validateRefNoTpl,
            invoiceRefNoTpl: validateRefNoTpl,
        });

        const classes = useStyles()

        return (
            <Accordion style={{ marginBottom: '24px' }} expanded={expanded} onChange={() => handleAccordion()}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Grid container spacing={2} direction="row">
                        <Grid item><Settings fontSize="small" /></Grid>
                        <Grid item>
                            <Box component='h5'>Manage Financial Year ID Reset Settings</Box>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Box px={4}>
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, actions) => {
                                if (temple?.url) {
                                    dispatch(templeUpdateThunk({
                                        url: temple.url, data: formtoAPi(values, initialValues)
                                    }))
                                        .then(unwrapResult)
                                        .then(() => {
                                            enqueueSnackbar('Temple Updated', { variant: 'success' })
                                            actions.resetForm()
                                        })
                                        .catch(err => {
                                        })
                                        .finally(() => {
                                            actions.setSubmitting(false)
                                        })
                                }
                            }}
                        >
                            {({ isValid, dirty, isSubmitting, setFieldValue, values, errors }) => (
                                <Form noValidate autoComplete='off'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControlLabel control={<Checkbox
                                                onChange={(e) => { setFieldValue('resetRefOnYearChange', e.target.checked) }}
                                                checked={values.resetRefOnYearChange !== '' ? values.resetRefOnYearChange : false}
                                                color='primary'
                                            />} label="Enable reset number on financial year change" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormikTextField
                                                type="number"
                                                name='refNoPadding'
                                                variant='outlined'
                                                fullWidth
                                                placeholder={"Padding"}
                                                label={"Padding"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormikTextField
                                                name='orderRefNoTpl'
                                                variant='outlined'
                                                fullWidth
                                                placeholder={"Order Number Template"}
                                                label={"Order Number Template"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormikTextField
                                                name='offeringReceiptRefNoTpl'
                                                variant='outlined'
                                                fullWidth
                                                placeholder={"Offering Receipt Number Template"}
                                                label={"Offering Receipt  Number Template"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormikTextField
                                                name='donationReceiptRefNoTpl'
                                                variant='outlined'
                                                fullWidth
                                                placeholder={"Donation Receipt Number Template"}
                                                label={"Donation Receipt Number Template"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormikTextField
                                                name='voucherRefNoTpl'
                                                variant='outlined'
                                                fullWidth
                                                placeholder={"Voucher Receipt Number Template"}
                                                label={"Voucher Receipt Number Template"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormikTextField
                                                name='invoiceRefNoTpl'
                                                variant='outlined'
                                                fullWidth
                                                placeholder={"Invoice Receipt Number Template"}
                                                label={"Invoice Receipt Number Template"}
                                            />
                                        </Grid>




                                        <Grid item xs={12}>
                                            <Box alignContent="flex-end">
                                                <Button
                                                    style={{ float: 'right' }}
                                                    disabled={isSubmitting || !isValid || !dirty}
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    {messages[buttonLabelId]}
                                                </Button>
                                            </Box>
                                        </Grid>
                                        {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                                            <Grid item xs={12}>
                                                <Alert severity="error">{extractError(api.error)}</Alert>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </AccordionDetails>
            </Accordion>



        )
    }

export default ReferenceResetForm