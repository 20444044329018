
import { Button, Card, CardContent, TextareaAutosize, TextField, Typography } from '@material-ui/core'
import HeadingCard from 'components/Card/HeadingCard'
import React, { useMemo, useState } from 'react'
import { ITempleResponse } from 'types/api/temple'
import ContentCopy from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';

type Props = {
    temple:ITempleResponse
}

const IframeCopyCard :React.FC<Props>= ({temple}) => {

    const [input, setInput] = useState('')
    const { enqueueSnackbar } = useSnackbar();

    const iframeUrl = useMemo(() => {
        return `<iframe width="100%" style="min-height: 100svh; padding: 0; margin: 0;" height="100%" src="https://plugin.templeclick.com/?t=${temple.id}&amp;o=${encodeURI(input)}" allow="geolocation *; web-share *; otp-credentials *;"></iframe>`
    }, [input])
    return (
        <HeadingCard title="Plugin">
            <TextField label={"Temple URL"} onChange={(e) => setInput(e.target.value)} multiline size='small' name='url' variant='outlined' fullWidth />

            <TextField   inputProps={{style: {fontSize: 10}}} label="Embed" size='small' style={{
                fontSize: 10,
                marginTop: 16
            }} disabled fullWidth multiline variant='outlined'
                value={iframeUrl}
            />

            <Button endIcon={<ContentCopy/>} onClick={() => {
                  navigator.clipboard.writeText(iframeUrl)
                  enqueueSnackbar("Copied", { variant: 'success' })
            }} style={{marginTop:16}}>Copy</Button>

        </HeadingCard>
    )
}

export default IframeCopyCard